<template>
  <page-wrapper title="ПОМОЩЬ В РАБОТЕ СЕРВИСА">
    <section class="fs-25 lh-35 t-blackDark">
      <h2 class="m-0 lh-30">Раздел находится в стадии наполнения.</h2>
      <p class="m-0 fw-l">
        Если у вас возникли вопросы. Звоните по телефону +7 918 667-02-69.
      </p>
      <p class="m-0 fw-l">Часы работы с 9:00 до 19:00, без выходных</p>
    </section>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@/components/Wrappers/PageWrapper'
export default {
  name: 'Help',
  components: { PageWrapper }
}
</script>
